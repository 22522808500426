<template>
	<div class="performance performance--overview">
		<div class="performance__col performance__col--2">
			<number-bars class="number-bars--on-green" :title="`Number \nof Active Security \nIssues Held at DTCC`" unit="( in millions )" :numbers="volumeActiveSecurity" />

			<number-bars class="number-bars--on-green" :title="`Total Value \nof Active Issues \nHeld at DTCC`" unit="( in trillions )" :numbers="valueActiveSecurity" />
		</div>
	</div>
</template>

<script>
import NumberBars from '@/components/NumberBars'

export default {
	name: 'SettlementAsset2',
	components: {
		NumberBars,
	},
	data: () => ({
		volumeActiveSecurity: [
			{
				value: 1.37,
				label: '2020',
				options: {
					decimalPlaces: 2,
				},
			},
			{
				value: 1.36,
				label: '2019',
				options: {
					decimalPlaces: 2,
				},
			},
			{
				value: 1.34,
				label: '2018',
				options: {
					decimalPlaces: 2,
				},
			},
		],
		valueActiveSecurity: [
			{
				value: 73.5,
				label: '2020',
				options: {
					prefix: '$',
					decimalPlaces: 1,
				},
			},
			{
				value: 63.0,
				label: '2019',
				options: {
					prefix: '$',
					decimalPlaces: 1,
				},
			},
			{
				value: 52.2,
				label: '2018',
				options: {
					prefix: '$',
					decimalPlaces: 1,
				},
			},
		],
	}),
}
</script>

<style scoped lang="scss">

</style>
